import React, { useState } from 'react'
import { Dialog, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

const Chart = ({ children }) => (
  <ul className="p-4 m-1 text-center list-none bg-white">{children}</ul>
)

const ChartHeader = ({ children }) => (
  <li className="text-open-blue text-3xl mb-8">{children}</li>
)

const ChartList = ({ children }) => (
  <li className="text-black text-xs border-t border-herokuGray uppercase p-2">
    {children}
  </li>
)

let open = true

const __setOpen = val => (open = val)

const PromoDialog = () => {
  const [_open, _setOpen] = useState(true)

  const setOpen = () => {
    _setOpen(false)
    __setOpen(false)
  }

  return (
    <Dialog onClose={() => setOpen(false)} open={open}>
      <IconButton
        style={{ position: 'absolute', right: 0, margin: '3px' }}
        aria-label="close"
        onClick={() => setOpen(false)}
      >
        <CloseIcon />
      </IconButton>
      <Chart>
        <ChartHeader>Limited Time Offer!</ChartHeader>
        <ChartList borderTop>50% OFF on Membership Program</ChartList>
        <ChartList>$175 for Regular Adult Membership</ChartList>
        <ChartList>
          You can have 2 regular cleaning & exams and X-rays
        </ChartList>
        <ChartList>
          2 Limited emergency exams, 2 topical fluoride treatments and 20%
          SAVINGS ON ALL DENTAL TREATMENTS YOU NEED.
          <div>
            <span className="text-paleGrey text-xs">
              (This offer is valid in adults without gum disease/periodontal
              disease)
            </span>
          </div>
        </ChartList>
        <ChartList>$19 Limited Exam with X-Rays and Consultation</ChartList>
        <ChartList>
          Take advantage of our $ 19 Limited Exam with X-Rays and Consultation.
          Call us with any dental emergency or toothache. We can make you smile!
        </ChartList>
      </Chart>
    </Dialog>
  )
}

export default PromoDialog
